import {
  ActiveItemTypeConstant,
  AuthHelper,
  C4UMenu,
  CorporateUserTypeEnum,
  IAuth0User,
  IDealershipComboData,
  IUser,
  StorageConstant,
} from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useC4u, useSessionContext } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { AuthManheimTemplate } from "../auth/auth.template-manheim";
import { AppContainerWithMenu } from "../base.template.style";

interface IProps {
  redirectUri: string;
  activeMenu: keyof typeof ActiveItemTypeConstant;
  hideChildrenIfNotAuthenticated?: boolean;
  onSelectDealershipSuccess?: () => void;
}

export const ManheimAuthC4U: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { coxUserContext, setCoxUserContext } = useSessionContext();

  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const { GetAuth0User, GetUser, postSelectDealership, getListMyDealerships } =
    useC4u();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        domain: process.env.REACT_APP_AUTH0_DOMAIN ?? "",
        clientId: process.env.REACT_APP_AUTH0_CLIENTID ?? "",
        audience: process.env.REACT_APP_AUTH0_AUDIENCE ?? "",
        scope: process.env.REACT_APP_AUTH0_SCOPE ?? "",
        redirectUri: props.redirectUri,
      });
    }
    // eslint-disable-next-line
  }, [isLoading, isAuthenticated]);

  //#region auth
  const [auth0User, setAuth0User] = useState<IAuth0User>();
  const [redirectedFromLogin, setRedirectedFromLogin] = useState(false);
  const [selectedDealership, setSelectedDealership] =
    useState<IDealershipComboData>();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      GetAuth0User().then((t) => setAuth0User(t));
      GetUser().then((t) => setCoxUserContext(t));
    }
    // eslint-disable-next-line
  }, [isLoading, isAuthenticated]);

  const [userCox, setUserCox] = useState<IUser>();
  useEffect(() => {
    if (coxUserContext && auth0User) {
      setUserCox(AuthHelper.GetCoxUser(coxUserContext, auth0User));
      const { dealershipId, dealershipName, dealershipCnpj } = coxUserContext;
      if (dealershipId && dealershipName && dealershipCnpj)
        setSelectedDealership({
          id: dealershipId,
          name: dealershipName,
          cnpj: dealershipCnpj,
        });
      setRedirectedFromLogin(
        sessionStorage.getItem(StorageConstant.redirectedFromLogin) === "true"
      );
    }
  }, [auth0User, coxUserContext]);
  //#endregion

  const handleLogout = () => {
    window.location.href = process.env.REACT_APP_LOGOUT_URL ?? "";
  };

  const clearKeysFromStorage = useCallback(() => {
    sessionStorage.removeItem(StorageConstant.redirectedFromLogin);
  }, []);

  return (
    <AppContainerWithMenu>
      <C4UMenu
        handleLogout={handleLogout}
        activeMenu={props.activeMenu}
        environment={process.env.REACT_APP_BUILD}
        user={userCox}
        selectDealershipProps={
          !userCox
            ? undefined
            : {
                userId: coxUserContext?.id,
                userIsManager:
                  coxUserContext?.corporateUserType ===
                  CorporateUserTypeEnum.Manager,
                userIsCorporate: coxUserContext?.isCorporate,
                dealership: selectedDealership,
                onSubmitCallback: postSelectDealership,
                onSuccessCallback: props.onSelectDealershipSuccess,
                redirectTo: props.redirectUri,
                getDealershipsPagedListCallback: getListMyDealerships,
                setSelectedDealership: setSelectedDealership,
                onHideCallback: clearKeysFromStorage,
                forceShowModal: redirectedFromLogin,
              }
        }
        translate={t}
        dealershipUser={coxUserContext?.dealershipCnpj}
      />

      {isAuthenticated && (
        <Auth0Provider
          connection={process.env.REACT_APP_AUTH0_MANHEIM_CONNECTION ?? ""}
          domain={process.env.REACT_APP_AUTH0_MANHEIM_DOMAIN ?? ""}
          clientId={process.env.REACT_APP_AUTH0_MANHEIM_CLIENTID ?? ""}
          audience={process.env.REACT_APP_AUTH0_MANHEIM_AUDIENCE ?? ""}
        >
          <AuthManheimTemplate>{props.children}</AuthManheimTemplate>
        </Auth0Provider>
      )}
    </AppContainerWithMenu>
  );
};
